export const resources_id = {
  navigation: 'navigation',
  dashboard: 'dashboard',
  creditReload: 'credit_reload',
  withheld: 'withheld',
  renewal: 'renewal',
  transports: 'transports',
  transportPassbook: 'transports_passbook',
  collectionRemark: 'collection_remark',
  nocLetter: 'noc',
  settings: 'settings',
  callRequest: 'call_request',
  users: 'users',
  preSubmit: 'pre_submit',
  report: 'report',
  dealershipNavigation: 'dealership_navigation',
  dealership: 'dealership',
  dealer: 'dealer',
  scoreCard: 'score_card',
  loansList: 'loans_list',
  personalDiscussion: 'personal_discussion',
  docChecklist: 'doc_checklist',
  transporters: 'transporters',
  fleetOperator: 'fleet_operator',
  PdcModule: 'pdc_moudule',
}

export const action_id = {
  navigation: {
    dashboard: 'dashboard',
    enhancement: 'enhancement',
    loans: 'loans',
    loansFuel_loans: 'loans:fuel_loans',
    loansVehicle_loans: 'loans:vehicle_loans',
    credit_reload: 'credit_reload',
    withheld: 'withheld',
    renewal: 'renewal',
    transports: 'transports',
    transportsList: 'transports:list',
    transportsPassbook: 'transports:passbook',
    collection_remarks: 'collection_remarks',
    report: 'report',
    reportLoan_due: 'report:loan_due',
    reportLoan_overdue: 'report:loan_overdue',
    reportOpportunity: 'report:opportunity',
    dealerships: 'dealerships',
    pre_submit: 'pre_submit',
    users: 'users',
    call_request: 'call_request',
    settings: 'settings',
    noc: 'noc',
    dashboardDealer: 'dashboard:dealer',
    dealer_referral: 'dealer_referral',
    profileDealer: 'profile:dealer',
    account_statement: 'account_statement',
    profileTransports: 'profile:transports',
  },
  dashboard: {
    sub_docs: 'sub_docs',
    edit_loantype: 'edit_loantype',
    send_for_review: 'send_for_review',
    sub_esign: 'sub_esign',
    loan_resubmit: 'loan_resubmit',
    loan_reject: 'loan_reject',
    send_for_approval: 'send_for_approval',
    pa_docs: 'pa_docs',
    loan_approve: 'loan_approve',
    appr_attachments: 'appr_attachments',
    appr_docs: 'appr_docs',
    add_disb_amt: 'add_disb_amt',
    disb_details_action: 'disb_details_action',
    los_switch: 'los_switch',
    vivriti: 'vivriti',
    loan_submit: 'loan_submit',
    submitted_documents: 'submitted_documents',
    pushback: 'pushback',
    reactivate: 'reactivate',
    dashboardSwitch: 'dashboardSwitch',
    pdcChecklist: 'pdcChecklist',
    pdcComplete: 'pdc_complete',
    pdc_re_initiate: 'pdc_re_initiate',
  },
  creditReload: {
    create: 'request:create',
    decline: 'request:decline',
    disburse: 'request:disburse',
    dealer_search: 'dealer_search',
    amount_edit: 'amount_edit',
  },
  withheld: {
    create: 'request:create',
    resolve: 'request:resolve',
    delete: 'request:delete',
  },
  renewal: {
    sanctionLetter: 'sanction_letter',
    loanAgreement: 'loan_agreement',
    esignApplication: 'esign_application',
    pdcChecklist: 'pdcChecklist'
  },
  transportPassbook: {
    upload: 'upload_statement',
    download: 'download_statement',
    share: 'share_statement',
  },
  nocLetter: {
    raiseRequest: 'noc:request',
    nocPreview: 'noc_preview',
    nocApprove: 'noc_approve',
    nocReject: 'noc_reject',
  },
  dealershipNavigation: {
    dealership: 'dealership',
    dealers: 'dealers',
    scoreCard: 'score_card',
    loansList: 'loans_list',
    personalDiscussion: 'personal_discussion',
    docChecklist: 'document_checklist',
    transporters: 'transporters',
    fleetOperator: 'fleet_operator',
    deferral: 'deferral',
    deviation: 'deviation',
  },
  dealership: {
    edit: 'dealership:edit',
    crimeCheck: 'dealership:crimeCheck'
  },
  dealer: {
    dealerAdd: 'dealer:add',
    dealerEdit: 'dealer:edit',
    dealerCrimeCheck: 'dealer:crimeCheck',
    dealerCreditCheck: 'dealer:creditCheck',
    dealerStatus: 'dealer:status',
    coapplicantAdd: 'coapplicant:add',
    coapplicantEdit: 'coapplicant:edit',
    coapplicantCrimeCheck: 'coapplicant:crimeCheck',
    coapplicantCreditCheck: 'coapplicant:creditCheck',
    coapplicantStatus: 'coapplicant:status',
    guarantorAdd: 'guarantor:add',
    guarantorEdit: 'guarantor:edit',
    guarantorCrimeCheck: 'guarantor:crimeCheck',
    guarantorCreditCheck: 'guarantor:creditCheck',
    guarantorStatus: 'guarantor:status',
    Vkyc: 'vkyc',
    applicantSwap: 'dealer_applicantSwap',
    applicantTypeChange: 'applicant_type_change',
  },
  scoreCard: {
    upload: 'scorecard:upload',
  },
  loansList: {
    action: 'loans_list:action',
    statement: 'account_statement',
    applicationStatus: 'application_status',
  },
  personalDiscussion: {
    omcView: 'omc:view',
    omcEdit: 'omc:edit',
    businessView: 'business:view',
    businessEdit: 'business:edit',
    outletView: 'outlet:view',
    outletEdit: 'outlet:edit',
    infrastructureView: 'infrastructure:view',
    infrastructureEdit: 'infrastructure:edit',
    infrastructureAddTanker: 'infrastructure:add_tanker',
    assetView: 'asset:view',
    assetEdit: 'asset:edit',
    assetAdd: 'asset:add',
    assetDelete: 'asset:delete',
    bankView: 'bank:view',
    bankAdd: 'bank:add',
    bankEdit: 'bank:edit',
    bankDelete: 'bank:delete',
    bankVerify: 'bank:verify',
    loanView: 'loan:view',
    loanAdd: 'loan:add',
    loanEdit: 'loan:edit',
    loanDelete: 'loanDelete',
    incomeExpenceView: 'income_expence:view',
    incomeAdd: 'income:add',
    incomeEdit: 'income:edit',
    incomeDelete: 'income:delete',
    expenceAdd: 'expence:add',
    expenceEdit: 'expence:edit',
    expenceDelete: 'expence:delete',
    thirdPartyView: 'third_party:view',
    referenceAdd: 'reference:add',
    referenceEdit: 'referenceEdit',
    referenceDelete: 'referenceDelete',
    otherView: 'other:view',
    bunkAdd: 'bunk:add',
    bunkEdit: 'bunk:edit',
    bunkDelete: 'bunk:delete',
    creditPdView: 'credit_pd:view',
    creditPdEdit: 'credit_pd:edit',
    callLogView: 'call_log:view',
    callInitiate: 'call:initiate',
    callLogDelete: 'call_log:delete',
    pdReport: 'pd:report',
    bankSync: 'bank_sync',
    manualBankVerify: 'manual_bank_verify',
    bankActivateDeactivate: 'bank_activate_deactivate',
  },
  docChecklist: {
    upload: 'doc:upload',
    edit: 'doc_name:edit',
    delete: 'doc:delete',
  },
  transporters: {
    addOwner: 'owner:add',
    editOwner: 'owner:edit',
    addTransport: 'transport:add',
    editTransport: 'transport:edit',
  },
  fleetOperator: {
    add: 'operator:add',
    edit: 'operator:edit'
  },
  settings: {
    zones: 'zones',
    states: 'states',
    regions: 'regions',
    city: 'city',
    omcs: 'omcs',
    business: 'business',
    loan: 'loan',
    asset: 'asset',
    products: 'products',
    assign_role_products: 'assign_role_products',
    collectionRemark: 'collection_remark',
    zonesAdd: 'zones:add',
    statesAdd: 'states:add',
    regionsAdd: 'regions:add',
    cityAdd: 'city:add',
    omcsAdd: 'omcs:add',
    businessAdd: 'business:add',
    loanAdd: 'loan:add',
    assetAdd: 'asset:add',
    productsAdd: 'products:add',
    addign_role_productsAdd: 'addign_role_products:add',
    collection_remarkAdd: 'collection_remark:add',
    statesDelete: 'states:delete',
    regionsDelete: 'regions:delete',
    omcsDelete: 'omcs:delete',
    businessDelete: 'business:delete',
    loanDelete: 'loan:delete',
    assetDelete: 'asset:delete',
    zonesUpdate: 'zones:update',
    statesUpdate: 'states:update',
    regionsUpdate: 'regions:update',
    cityUpdate: 'city:update',
    omcsUpdate: 'omcs:update',
    businessUpdate: 'business:update',
    loanUpdate: 'loan:update',
    asssetUpdate: 'assset:update',
    productsUpdate: 'products:update',
    assign_role_productsUpdate: 'assign_role_products:update',
    collection_remarkUpdate: 'collection_remark:update',
    emailGroup: 'emailGroup'
  },
  users: {
    userCreate: 'user:create',
    userEdit: 'user:edit',
    userChange_password: 'user:change_password',
    user_delete: 'user_delete',
    userStatus: 'user:status',
  },
  PdcModule: {
    createBank: 'bank_create',
    createCheque: 'cheque_create',
    addTransit: 'transit_add',
    addDpn: 'dpn_add',
  }
}