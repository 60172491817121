export const URL = {
  base: process.env.REACT_APP_API_BASE,
  login: 'login/user',
  verifyMobileNumber: 'login/otp',
  resendOtp: 'otp/resend',
  allUsers: 'users',
  userRoles: 'user/roles',
  addNewUser: 'signup',
  loans: 'loans',
  sanction: 'loans/sanction',
  dealership: 'dealership',
  transport: 'transporterslist',
  vehicleInfo: 'transporters',
  dealers: 'dealers',
  coApplicants: 'coapplicants',
  checklist: 'checklist',
  applicants: 'applicants/dealership',
  salesInfo: 'sales/dealership',
  creditInfo: 'credit/info',
  creditReport: 'credit/report',
  upload: 'upload',
  loanBook: 'business/loanbook',
  loanMetrics: 'metrics/loan/stats',
  report: 'loan/report',
  region: 'regions/all',
  regionMap: 'regionMap',
  regionMapUser: 'region-map-user/',
  regionDel: 'region-del/',
  regionMapAdd: 'region-map-add/',
  passReset: 'passReset',
  guarantor: 'guarantors',
  ifscApiUrl: 'https://ifsc.razorpay.com/',
  cerbos: 'user/access'
}
