import { NavLink } from '@mantine/core'
import React from 'react';

export const Link = ({
  key,
  color,
  variant = 'light',
  href,
  active,
  label,
  description,
  leftSection,
  rightSection,
  onClick,
  disabled,
  childrenOffset,
  defaultOpened,
  children,
  visibleFrom
}) => {
  return (
    <NavLink
      key={key}
      color={color}
      variant={variant}
      href={href}
      active={active}
      label={label}
      description={description}
      leftSection={leftSection}
      rightSection={rightSection}
      onClick={onClick}
      // style={style}
      disabled={disabled}
      childrenOffset={childrenOffset}
      defaultOpened={defaultOpened}
      visibleFrom={visibleFrom}
      styles={{
        root: {
          borderRadius: 4,
        },
        children: {
          display: 'flex',
          flexDirection: 'column',
          gap: 4
        },
        label: {
          fontSize: '13px',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 500,
          color: 'rgb(50,50,50)'
        },
        section: {
          color: '#adaeaf'
        },
        chevron: {
          color: 'rgb(50, 50, 50)'
        }
      }}
      py={7}
    >
      {children}
    </NavLink>
  )
}