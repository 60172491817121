export const rulesList = {
  'dashboard': 'dashboard',
  'users_view': 'users:view',
  'loan_approval': 'loan:approval',
  'dealership_edit': 'dealership:edit',
  'dealer_edit': 'dealer:edit',
  'dealer_credit_edit': 'dealer:credit:edit',
  'dealership_credit_edit': 'dealership:credit:edit',
  'dealer_credit_view': 'dealer:credit:view',
  'dealership_credit_view': 'dealership:credit:view',
  'dealer_view': 'dealer:view',
  'transporter_view': 'transporter:view',
  'region_map': 'region:map',
  'settings_view': 'settings:view',
  'credit_view': 'credit:view',
  'credit_refresh': 'credit:refresh',
  'pdr_view': 'sales:view',
  'financial_view': 'financial:view',
  'upload_statement': 'upload:statement',
  'opportunity_report': 'opportunity:report',
  'applicant_delete': 'applicant:delete',
  'external_view': 'external:view',
  'dealership_view': 'dealership:view',
  'external_lms': 'external:lms',
  'phone_call': 'phonecall:pdr',
  'ops_view': 'ops:view',
  'pre_submit_view': 'pre:submit:view',
  'admin_edit': 'admin:edit',
  'cibil_edit': 'cibil:edit',
  'vkyc_edit': 'vkyc:edit',
  'crime_check': 'crime:check:edit',
}

export const rulesForUserRoles = {
  CEO: {
    static: [
      rulesList.dashboard,
      rulesList.loan_approval,
      rulesList.dealer_credit_view,
      rulesList.dealership_credit_view,
      rulesList.credit_view,
      rulesList.credit_refresh,
      rulesList.pdr_view,
      rulesList.financial_view,
      rulesList.upload_statement,
      rulesList.external_lms,
      rulesList.opportunity_report,
      rulesList.pre_submit_view,
      rulesList.dealership_view,
      rulesList.cibil_edit,
      rulesList.vkyc_edit,
      rulesList.crime_check,
    ]
  },
  CDO: {
    static: [
      rulesList.dashboard,
      rulesList.loan_approval,
      rulesList.dealer_credit_view,
      rulesList.dealership_credit_view,
      rulesList.credit_view,
      rulesList.credit_refresh,
      rulesList.pdr_view,
      rulesList.financial_view,
      rulesList.upload_statement,
      rulesList.external_lms,
      rulesList.pre_submit_view,
      rulesList.opportunity_report,
      rulesList.dealership_view,
      rulesList.cibil_edit,
      rulesList.vkyc_edit,
      rulesList.crime_check,
    ]
  },
  CFO: {
    static: [
      rulesList.dashboard,
      rulesList.loan_approval,
      rulesList.dealership_edit,
      rulesList.dealer_edit,
      rulesList.dealer_credit_edit,
      rulesList.dealership_credit_edit,
      rulesList.credit_view,
      rulesList.credit_refresh,
      rulesList.pdr_view,
      rulesList.financial_view,
      rulesList.upload_statement,
      rulesList.external_lms,
      rulesList.opportunity_report,
      rulesList.pre_submit_view,
      rulesList.dealership_view,
      rulesList.cibil_edit,
      rulesList.vkyc_edit,
      rulesList.crime_check,
    ]
  },
  CREDIT_HEAD: {
    static: [
      rulesList.dashboard,
      rulesList.dealership_edit,
      rulesList.dealer_edit,
      rulesList.loan_approval,
      rulesList.dealer_credit_view,
      rulesList.dealership_credit_view,
      rulesList.credit_view,
      rulesList.credit_refresh,
      rulesList.pdr_view,
      rulesList.financial_view,
      rulesList.upload_statement,
      rulesList.phone_call,
      rulesList.pre_submit_view,
      rulesList.dealership_view,
      rulesList.vkyc_edit,
      rulesList.crime_check,
      rulesList.cibil_edit,
    ]
  },
  ADMIN: {
    static: [
      rulesList.loan_approval,
      rulesList.dashboard,
      rulesList.dealership_edit,
      rulesList.dealer_edit,
      rulesList.dealer_credit_edit,
      rulesList.dealership_credit_edit,
      rulesList.users_view,
      rulesList.region_map,
      rulesList.ops_view,
      rulesList.settings_view,
      rulesList.credit_view,
      rulesList.credit_refresh,
      rulesList.pdr_view,
      rulesList.financial_view,
      rulesList.upload_statement,
      rulesList.applicant_delete,
      rulesList.external_lms,
      rulesList.phone_call,
      rulesList.opportunity_report,
      rulesList.pre_submit_view,
      rulesList.dealership_view,
      rulesList.admin_edit,
      rulesList.cibil_edit,
      rulesList.vkyc_edit,
      rulesList.crime_check,
    ]
  },
  CREDIT_EXEC: {
    static: [
      rulesList.loan_approval,
      rulesList.dashboard,
      rulesList.dealership_edit,
      rulesList.dealer_edit,
      rulesList.dealer_credit_edit,
      rulesList.dealership_credit_edit,
      rulesList.credit_view,
      rulesList.credit_refresh,
      rulesList.pdr_view,
      rulesList.financial_view,
      rulesList.upload_statement,
      rulesList.region_map,
      rulesList.phone_call,
      rulesList.pre_submit_view,
      rulesList.dealership_view,
      rulesList.vkyc_edit,
      rulesList.crime_check,
      rulesList.cibil_edit,
    ]
  },
  OPS_EXEC: {
    static: [
      rulesList.dashboard,
      rulesList.dealer_credit_view,
      rulesList.loan_approval,
      rulesList.dealership_credit_view,
      rulesList.credit_view,
      rulesList.credit_refresh,
      rulesList.pdr_view,
      rulesList.financial_view,
      rulesList.upload_statement,
      rulesList.external_lms,
      rulesList.ops_view,
      rulesList.dealership_view,
      rulesList.cibil_edit,
      rulesList.vkyc_edit,
      rulesList.crime_check,
    ]
  },
  OPS_MANAGER: {
    static: [
      rulesList.dashboard,
      rulesList.dealer_credit_view,
      rulesList.dealership_credit_view,
      rulesList.loan_approval,
      rulesList.credit_view,
      rulesList.credit_refresh,
      rulesList.pdr_view,
      rulesList.financial_view,
      rulesList.upload_statement,
      rulesList.external_lms,
      rulesList.ops_view,
      rulesList.dealership_view,
      rulesList.cibil_edit,
      rulesList.vkyc_edit,
      rulesList.crime_check,
    ]
  },
  CREDIT: {
    static: [
      rulesList.dashboard,
      rulesList.dealership_edit,
      rulesList.dealer_edit,
      rulesList.dealer_credit_edit,
      rulesList.dealership_credit_edit,
      rulesList.credit_view,
      rulesList.credit_refresh,
      rulesList.pdr_view,
      rulesList.financial_view,
      rulesList.upload_statement,
      rulesList.region_map,
      rulesList.phone_call,
      rulesList.pre_submit_view,
      rulesList.dealership_view,
      rulesList.crime_check,
      rulesList.cibil_edit,
    ]
  },
  CREDIT_MANAGER: {
    static: [
      rulesList.dashboard,
      rulesList.loan_approval,
      rulesList.pre_submit_view,
      rulesList.dealership_view,
      rulesList.credit_refresh,
      rulesList.dealership_edit,
      rulesList.credit_refresh,
    ]
  },
  SALES_HEAD_STATE: {
    static: [
      rulesList.dashboard,
      rulesList.dealer_credit_view,
      rulesList.dealership_credit_view,
      rulesList.pdr_view,
      rulesList.dealership_edit,
      rulesList.credit_view,
      rulesList.credit_refresh,
      rulesList.financial_view,
      rulesList.upload_statement,
      rulesList.pre_submit_view,
      rulesList.cibil_edit,
    ]
  },
  SALES_HEAD_REGIONAL: {
    static: [
      rulesList.dashboard,
      rulesList.dealer_credit_view,
      rulesList.dealership_credit_view,
      rulesList.dealership_edit,
      rulesList.pdr_view,
      rulesList.credit_view,
      rulesList.credit_refresh,
      rulesList.financial_view,
      rulesList.upload_statement,
      rulesList.pre_submit_view,
      rulesList.cibil_edit,
    ]
  },
  FIELD_OFFICER: {
    static: [
      rulesList.dashboard,
      rulesList.dealership_edit,
      rulesList.dealer_edit,
      rulesList.dealer_credit_view,
      rulesList.dealership_credit_view,
      rulesList.credit_view,
      rulesList.credit_refresh,
      rulesList.pdr_view,
      rulesList.financial_view,
      rulesList.upload_statement,
      rulesList.pre_submit_view,
      rulesList.cibil_edit,
    ]
  },
  DEALER: {
    static: [
      rulesList.dealer_view,
      rulesList.dealership_view,
    ]
  },
  TRANSPORTER: {
    static: [
      rulesList.transporter_view,
    ]
  },
  EXTERNAL: {
    static: [
      rulesList.external_view,
      rulesList.pdr_view,
      rulesList.credit_view,
      rulesList.dealership_view,
      rulesList.financial_view,
      rulesList.dashboard,
    ]
  },
  GOSREE: {
    static: [
      rulesList.external_view,
      rulesList.pdr_view,
      rulesList.credit_view,
      rulesList.dealership_view,
      rulesList.financial_view,
      rulesList.dashboard,
    ]
  },
}