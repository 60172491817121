export const STATUS_COLOR = {
  'submitted': 'gray',
  'loan_review': 'blue',
  'loan_approval': 'blue',
  'approved': 'green',
  'disbursement_approval': 'green',
  'disbursement_approved': 'green',
  'disbursed': 'teal',
  'rejected': 'red'
}

export const STATUS = {
  'Pending Approval': 'loan_approval',
  'Pending Review': 'loan_review',
  'Disb. Approval': 'disbursement_approval',
  'Submitted': 'submitted',
  'Approved': 'approved',
  'Rejected': 'rejected',
  'Disbursed': 'disbursed',
  'Disb. Approved': 'disbursement_approved',
}