export const MuiPickersBasePicker = {
  container: {
  },
  pickerView: {
    minWidth: 275,
    minHeight: 285,
  }
};

export const MuiPickersDay = {
  day: {
    width: 32,
    height: 32,
  },
  daySelected: {
    
  }
};

export const MuiPickersCalendarHeader = {
  switchHeader: {},
  daysHeader: {},
  dayLabel: {
    width: 32
  },
}

export const MuiPickersCalendar = {
  transitionContainer: {
    minHeight: 200
  }
}

export const MuiPickersToolbar = {
  toolbar: {
    height: 64,
    '& .MuiPickersToolbarText-toolbarTxt': {
      '&.MuiTypography-h4': {
        fontSize: 16,
      }
    }
  }
}
