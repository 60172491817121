import {
  Box,
  Grid,
  Button,
  Divider,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import LoaderButton from '../../components/CommonComponents/Button/LoaderButton';
import { ViewData } from '../../components/CommonComponents/FilePreview';
import { action_id, resources_id } from '../../config/accessControl';
import {
  approveNocRequestbyDealershipID,
  rejectNocRequestbyDealershipID,
} from '../../services/noc.services';
import CheckAllowed from '../rbac/CheckAllowed';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';

const useStyles = makeStyles((theme) => ({
  sidePanelFormWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },

  sidePanelTitle: {
    padding: '12px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 0,
    boxShadow: '0 1px 4px -3px #333',
  },

  sidePanelFormContentWrapper: {
    flex: 1,
    overflow: 'auto',
  },
  stepperRoot: {
    padding: 16,
    paddingTop: 20,
  },
  actionButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 16px',
  },
  btnError: {
    '&.MuiButton-contained': {
      marginRight: '8px',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.white
    },
  },
  editButton: {
    marginRight: '8px',
    '&.MuiButton-contained': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.white,
    },
    '&.MuiButton-contained:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));
const ApproveNocForm = ({ data, callback, currentUser, view }) => {
  const classes = useStyles();
  const [remark, setRemark] = useState('');
  const [loading, setLoading] = useState({ approve: false, reject: false });

  const handleReject = () => {
    setLoading({ ...loading, reject: true })
    rejectNocRequestbyDealershipID(data?.dealership_id, remark)
      .then((message) => {
        displayNotification({
          message: message,
          variant: 'success',
        })
        setLoading({ ...loading, reject: false });
        callback();
      })
      .catch((e) => {
        displayNotification({
          message: e,
          variant: 'error',
        })
        setLoading({ ...loading, reject: false });
        callback();
      });
  };

  const handleSubmit = () => {
    setLoading({ ...loading, approve: true })
    approveNocRequestbyDealershipID(data?.dealership_id, remark)
      .then((message) => {
        displayNotification({
          message: message,
          variant: 'success',
        })
        setLoading({ ...loading, approve: false });
        callback();
      })
      .catch((e) => {
        displayNotification({
          message: e,
          variant: 'success',
        })
        setLoading({ ...loading, approve: false });
        callback();
      });
  };

  const handleChange = (event) => {
    setRemark(event.target.value);
  };
  return (
    <div className={classes.sidePanelFormWrapper}>
      <>
        <div className={classes.sidePanelFormContentWrapper}>
          <div className={classes.stepperRoot}>
            <Grid container spacing={2}>
              <Grid item md={6}><ViewData title={'Dealership ID'} value={data?.dealership_id} /></Grid>
              <Grid item md={6}><ViewData title={'Dealership Name'} value={data?.name} /></Grid>
              <Grid item md={6}><ViewData title={'Applicant code'} value={data?.applicant_code} /></Grid>
              <Grid item md={6}><ViewData title={'NOC Type'} value={data?.noc_type} /></Grid>
            </Grid>
            <Box style={{ marginTop: 20 }}>
              <form>
                <Grid item md={7}>
                  <label style={{ marginBottom: 8 }}>Remarks</label>
                  <TextField
                    name="remark"
                    fullWidth
                    multiline
                    variant="outlined"
                    value={remark}
                    onChange={handleChange}
                  />
                </Grid>
              </form>
            </Box>
          </div>
        </div>
        <div className={classes.actionFooter}>
          <Divider />
          <div className={classes.actionButtonsWrapper}>
            <div>
              <Button variant="outlined" onClick={callback}>
                Back
              </Button>
            </div>
            <div style={{ display: 'flex' }}>
              <CheckAllowed currentUser={currentUser} resource={resources_id?.nocLetter} action={action_id.nocLetter?.nocReject}>
                <LoaderButton
                  variant="contained"
                  className={clsx(classes.btn, classes.btnError)}
                  isLoading={loading?.reject}
                  loadingText="Rejecting..."
                  type="submit"
                  onClick={handleReject}
                >
                  Reject
                </LoaderButton>
              </CheckAllowed>
              <CheckAllowed currentUser={currentUser} resource={resources_id?.nocLetter} action={action_id.nocLetter?.nocApprove}>
                <LoaderButton
                  variant="contained"
                  className={clsx(classes.btn, classes.editButton)}
                  isLoading={loading?.approve}
                  loadingText='Approving...'
                  type="submit"
                  onClick={handleSubmit}
                >
                  Approve
                </LoaderButton>
              </CheckAllowed>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ApproveNocForm;
