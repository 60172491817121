import typography from '../typography';

export default {
  fontWeightMedium: 600,
  gutterBottom: {
    marginBottom: 8
  },
  root: {
    '&.MuiFormControlLabel-label': {
      ...typography.body2
    }
  },
};
