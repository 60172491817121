import { URL } from '../config/serverUrls';
import apiCall from '../utils/api.util';

export const getBusinessTypes = () => {
  return new Promise((resolve, reject) => {
    apiCall('business/types')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getOmcList = () => {
  return new Promise((resolve, reject) => {
    apiCall('omcs')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};


export const downloadPDF = ({ file, isBase64, name }) => {
  const linkSource = isBase64 ? `data:application/pdf;base64,${file}` : file;
  const downloadLink = document.createElement('a');
  const fileName = `${name}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const getAllRegions = (id) => {
  return new Promise((resolve, reject) => {
    let apiUrl = 'regions/los';
    if (id !== '0' && id !== '' && id) apiUrl += `?zone=${id}`;
    apiCall(apiUrl, {}, 'GET')
      .then((response) => {
        if (response?.status === 'SUCCESS') {
          const result = response?.data.map((item) => ({
            label: item.region,
            value: item.id,
          }));
          resolve(result || []);
        } else {
          reject(new Error(response.message || 'Unable to get regions'));
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllRegionByStateId = (filterQry = {}) => {
  return new Promise((resolve, reject) => {
    const { state } = filterQry;
    let apiUrl = `regions?states=${state}`;
    if (state && state != '') {
      apiCall(apiUrl, {}, 'GET')
        .then((response) => {
          if (response?.status === 'SUCCESS') {
            const result = response?.data.map((item) => ({
              label: item.name,
              value: item.id?.toString(),
            }));
            resolve(result || []);
          } else {
            reject(new Error(response.message || 'Unable to get regions'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

export const getAllRegion = () => {
  return new Promise((resolve, reject) => {
    apiCall(URL.region)
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const getMappedRegion = (id) => {
  return new Promise((resolve, reject) => {
    apiCall(`user/${id}/map/region`)
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const updateMappedRegion = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`user/${id}/map/region`, {
      method: 'POST',
      body: {
        regions: data,
      },
    })
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const deleteMappedRegion = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`user/${id}/map/region`, {
      method: 'DELETE',
      body: {
        regions: data,
      },
    })
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const getAllRegionByState = (state) => {
  return new Promise((resolve, reject) => {
    let apiUrl = `master/pincode?state=${state}`;
    if (state !== '') {
      apiCall(apiUrl, {}, 'GET')
        .then((response) => {
          if (response?.status === 'SUCCESS') {
            const result = response?.data.map((item) => ({
              label: item.name,
              value: item.id,
            }));
            resolve(result || []);
          } else {
            reject(new Error(response.message || 'Unable to get regions'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

export const getAllCityByRegionId = (filterQry = {}) => {
  return new Promise((resolve, reject) => {
    const { region } = filterQry
    let apiUrl = `master/pincode?region=${region}`;
    if (region !== '') {
      apiCall(apiUrl, {}, 'GET')
        .then((response) => {
          if (response?.status === 'SUCCESS') {
            const result = response?.data.map((item) => ({
              label: item.name,
              value: item.id?.toString(),
            }));
            resolve(result || []);
          } else {
            reject(new Error(response.message || 'Unable to get regions'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

export const getAllUnmappedPincodeByCity = (filterQry = {}) => {
  return new Promise((resolve, reject) => {
    const { city } = filterQry
    let apiUrl = `unmapped/pincode?city=${city}`;
    if (city !== '') {
      apiCall(apiUrl, {}, 'GET')
        .then((response) => {
          if (response?.status === 'SUCCESS') {
            const result = response?.data.map((item) => ({
              city: item?.city,
              label: item.pincode,
              value: item.pincode,
            }));
            resolve(result || []);
          } else {
            reject(new Error(response.message || 'Unable to get pincode'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });

}

export const getAllPincodeByCityId = (filterQry) => {
  return new Promise((resolve, reject) => {
    const { city } = filterQry
    let apiUrl = `master/pincode?city=${city}`;
    if (city !== '') {
      apiCall(apiUrl, {}, 'GET')
        .then((response) => {
          if (response?.status === 'SUCCESS') {
            const result = response?.data.map((item) => ({
              city: item?.city,
              label: item.pincode,
              value: item.pincode,
            }));
            resolve(result || []);
          } else {
            reject(new Error(response.message || 'Unable to get regions'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};
export const getAllMappedPincode = (userId) => {
  return new Promise((resolve, reject) => {
    let apiUrl = `user/${userId}/map/pincode`;
    apiCall(apiUrl, {}, 'GET')
      .then((response) => {
        if (response?.status === 'SUCCESS') {
          resolve(response.data || []);
        } else {
          reject(new Error(response.message || 'Unable to get regions'));
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const updatePassword = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`user/${id}`, {
      method: 'POST',
      body: {
        password: data.password,
      },
    })
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const updateUserDetails = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`user/${id}`, {
      method: 'POST',
      body: data,
    })
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const SendReports = () => {
  return new Promise((resolve, reject) => {
    apiCall('loan/report/1', {
      method: 'POST',
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve({ status, message });
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const getPassbookDetails = (id, action) => {
  let apiUrl = `dealership/${id}/passbook`;
  if (action === 'download') apiUrl += '?send=1&download=1';
  if (action === 'share') apiUrl += '?send=1';
  return new Promise((resolve, reject) => {
    apiCall(apiUrl)
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve({ data, message });
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const getStates = () => {
  return new Promise((resolve, reject) => {
    apiCall('master/states')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getRelationshipList = () => {
  return new Promise((resolve, reject) => {
    apiCall('relationships')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getAssetType = () => {
  return new Promise((resolve, reject) => {
    apiCall('asset')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getActiveStates = () => {
  return new Promise((resolve, reject) => {
    apiCall('states')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
export const getMenuItemsCount = () => {
  return new Promise((resolve, reject) => {
    apiCall('count')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || {});
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getRegionById = (res) => {
  return new Promise((resolve, reject) => {
    apiCall(`states/regions/${res}`)
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const updateOmcsById = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`omcs/${id}`, {
      method: 'POST',
      body: data,
    })
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const updateRegionById = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`master/regions/${id}`, {
      method: 'POST',
      body: data,
    })
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const updateStateById = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`master/states/${id}`, {
      method: 'POST',
      body: data,
    })
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const updateAssetById = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`asset/${id}`, {
      method: 'POST',
      body: data,
    })
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const updateBusinessById = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`business/types/${id}`, {
      method: 'POST',
      body: data,
    })
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const updateLoanById = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`loan/types/${id}`, {
      method: 'POST',
      body: data,
    })
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const addOmcs = (data) => {
  return new Promise((resolve, reject) => {
    apiCall('omcs', {
      method: 'POST',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const addRegion = (data) => {
  return new Promise((resolve, reject) => {
    apiCall('regions', {
      method: 'POST',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const addState = (data) => {
  return new Promise((resolve, reject) => {
    apiCall('master/states', {
      method: 'POST',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const addBusinessType = (data) => {
  return new Promise((resolve, reject) => {
    apiCall('business/types', {
      method: 'POST',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const addLoanType = (data) => {
  return new Promise((resolve, reject) => {
    apiCall('loan/types', {
      method: 'POST',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const addAssetType = (data) => {
  return new Promise((resolve, reject) => {
    apiCall('asset', {
      method: 'POST',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const deleteOmcs = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`omcs/${id}`, {
      method: 'DELETE',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const deleteRegion = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`master/regions/${id}`, {
      method: 'DELETE',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const deleteState = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`master/states/${id}`, {
      method: 'DELETE',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const deleteAsset = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`asset/${id}`, {
      method: 'DELETE',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const deleteBusiness = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`business/types/${id}`, {
      method: 'DELETE',
      // body: data
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
export const deleteLoan = (data, id) => {
  return new Promise((resolve, reject) => {
    apiCall(`loan/types/${id}`, {
      method: 'DELETE',
      // body: data
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const getDealershipForSearch = (id) => {
  return new Promise((resolve, reject) => {
    apiCall(`dealership/search?status=disbursed&dealership=${id}`)
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getLoanTypes = () => {
  return new Promise((resolve, reject) => {
    apiCall('loan/types')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const getUserRoleForReview = (status) => {
  return new Promise((resolve, reject) => {
    apiCall(`users?${status}`)
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getProducts = () => {
  return new Promise((resolve, reject) => {
    apiCall('business/products/valid')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          const result = data?.map((item) => ({
            label: item.product_name,
            value: item.product_id,
          }));
          resolve(result || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getProductsMaster = () => {
  return new Promise((resolve, reject) => {
    apiCall('business/products')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getPdcChecklistCategoryMaster = () => {
  return new Promise((resolve, reject) => {
    apiCall('master/pre_disbursal_document_checklist/category')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getPdcChecklistMaster = () => {
  return new Promise((resolve, reject) => {
    apiCall('master/pre_disbursal_document_checklist')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getEntities = () => {
  return new Promise((resolve, reject) => {
    apiCall('entities')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getZones = (filter) => {
  return new Promise((resolve, reject) => {
    let apiUrl = 'zones';
    if (filter === 1) apiUrl += '?filter=1';
    apiCall(apiUrl)
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
export const getEntity = () => {
  return new Promise((resolve, reject) => {
    let apiUrl = 'lms/entities';
    apiCall(apiUrl)
      .then(({ status, data, message }) => {
        if (status.toUpperCase() === 'SUCCESS') {
          const result = data?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }));
          resolve(result || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const insertNewProduct = (data) => {
  return new Promise((resolve, reject) => {
    apiCall('business/products', {
      method: 'POST',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const addZones = (data) => {
  return new Promise((resolve, reject) => {
    apiCall('zones', {
      method: 'POST',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const updateProductbyId = (id, data) => {
  return new Promise((resolve, reject) => {
    apiCall(`business/products/${id}`, {
      method: 'POST',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const editZones = (id, data) => {
  return new Promise((resolve, reject) => {
    apiCall(`zones/${id}`, {
      method: 'POST',
      body: data,
    })
      .then(({ status, message }) => {
        if (status === 'SUCCESS') {
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const getFilteredProducts = () => {
  return new Promise((resolve, reject) => {
    apiCall('products/los')
      .then((response) => {
        if (response?.status === 'SUCCESS') {
          const result = response?.data.map((item) => ({
            label: item.product_name,
            value: item.product_id,
          }));
          resolve(result || []);
        } else {
          reject(new Error(response.message || 'Unable to get products'));
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getScoreCard = (dealership_id) => {
  return new Promise((resolve, reject) => {
    apiCall(`dealership/${dealership_id}/scorecard`)
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data || []);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getSignedUrl = (data) => {
  return new Promise((resolve, reject) => {
    apiCall('cloudfront/signed', {
      method: 'POST',
      body: {
        url: data
      },
    })
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const getUdyamVerified = ({ body }) => {
  return new Promise((resolve, reject) => {
    apiCall(`udyam/${body?.udyam_no}?dealership_id=${body?.dealership_id}`, {
      method: 'POST',
      body: {
        body
      },
    })
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data);
        } else {
          reject(message);
        }
      })
      .catch((e) => {
        reject(e.message);
      });
  });
}