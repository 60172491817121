import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist'
import rootReducer from './root-reducer';

const middleware = [];

export const store = createStore(
  rootReducer,
  applyMiddleware(...middleware)
);

export const persistor = persistStore(store);

export default { store, persistor };