import React from 'react';
import DealershipsTable from './components/DealershipsTable';

const Dealership = ({ currentUser }) => {
  // usePageTitle('Dealership List');
  return (
    <DealershipsTable />
  );
}

export default Dealership;