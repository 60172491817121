export default {
  elevation1: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    boxShadow: 'none',
    // border: '1px solid #ddd'
  },
  elevation4: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    boxShadow: 'none',
    // border: '1px solid #ddd'
  }
};
