export const getPastYears = (year=5) => {
  const PastYears = []
  const date = new Date();
  const currentYear = date.getFullYear();
  for (let i = 0; i < year; i++) {
    PastYears.push(currentYear - i)
  }
  return PastYears;
}

export const getMonth = [
  { label: 'January', value: 1 },
  { label: 'Feburary', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
]


export const numInWords = (num) => {
  let a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
  let b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
  if ((num = num?.toString())?.length > 9) return 'overflow';
  let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return; var str = '';
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
  return str = str.charAt(0).toUpperCase() + str.slice(1);
}

export const dateCustomSort = (data, dataIndex, rowIndex, dateIndex) => {
  if (dataIndex === dateIndex) {
    return data.sort((a, b) => {
      const dateA = new Date(a.data[dataIndex]).getTime();
      const dateB = new Date(b.data[dataIndex]).getTime();
      return (dateA < dateB ? -1 : 1) * (rowIndex === 'desc' ? 1 : -1);
    });
  } 
  else {
    return data.sort((a, b) => {
      return (
        (a.data[dataIndex] < b.data[dataIndex] ? -1 : 1) *
        (rowIndex === 'desc' ? 1 : -1)
      );
    });
  }
}